import React, { useContext } from 'react';
import { BsArrowReturnRight } from 'react-icons/bs';
import { GlobalDataContext } from '../../context/context';
import { ButtonContent } from '../global/boton/ButtonContent';


const Block_7 = ({ title, text, listsAbout, listsServices, sloganPrincipal }) => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div className='text-black py-[150px] relative'>

            <div className='w-[95%] md:w-[95%] mx-auto'>
                <div className=' flex flex-col-reverse md:flex-row px-1 md:px-10'>
                    <div className=' w-full md:w-1/2 rounded-md mr-0 -mb-20 mt-5 md:mr-2 md:-my-20 px-10 py-12 flex flex-col justify-center text-center md:text-start' data-aos="zoom-in" data-aos-duration="800">
                        {
                            sloganPrincipal ?
                                rpdata?.dbPrincipal?.licensed ?
                                    <h2 className='pb-3 capitalize text-black'>
                                        {rpdata?.dbPrincipal?.licensed}
                                    </h2>
                                    : <h3 className='pb-10 capitalize text-black'>
                                        {
                                            rpdata?.dbPrincipal?.exprYears ?
                                                `we have ${rpdata?.dbPrincipal?.exprYears} years of experience`
                                                : 'we have many years of experience'
                                        }
                                    </h3>
                                :
                                <h2 className='pb-3 capitalize text-black'>
                                    {
                                        title ? title :
                                            <span className='lowercase text-[18px]'>
                                                {`default title={'frase'}`} o
                                                {` agregar licencia o años de experienceias sloganPrincipal={true}`}
                                            </span>
                                    }
                                </h2>
                        }
                        <p className='pb-3'>
                            {
                                text ? text :
                                    <span>{`para agregar el texto -> text={'description'}`}
                                        <br />{'para agregar lista de about -> listsAbout={true}'}
                                        <br />{'para agregar lista de servicios -> listsServices={true}'}
                                    </span>
                            }
                        </p>
                        {
                            listsAbout ?
                                <ul className='grid grid-cols-1 md:grid-cols-2 pb-5'>
                                    {
                                        rpdata?.dbAbout?.[0].list.length > 1 ?
                                            rpdata?.dbAbout?.[0].list.map((item, index) => {
                                                return (

                                                    <li key={index} className="py-2 flex items-center">
                                                        <BsArrowReturnRight />
                                                        <span className="pl-2">{item}</span>
                                                    </li>
                                                )
                                            })
                                            : null
                                    }
                                </ul>
                                : null
                        }
                        {
                            listsServices ?
                                <ul className="grid grid-cols-1 md:grid-cols-2 pb-5">
                                    {rpdata?.dbServices?.slice(0, 6).map((item, index) => {
                                        return (
                                            <li key={index} className="py-1 flex items-center">
                                                <BsArrowReturnRight />
                                                <span>{item.name}</span>
                                            </li>
                                        )
                                    })}
                                </ul>
                                : null
                        }

                        <div>
                            <ButtonContent btnStyle={'one'} btnUrl={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`} btnName={'Call Us Now'} />
                        </div>
                    </div>
                    <div className='w-full h-auto flex flex-row md:w-1/2 space-x-2 ml-0 -mt-20 mb-0 md:ml-2 md:-my-20'>
                        <div className='w-[48%] h-[300px] md:h-[430px] bg-center bg-no-repeat bg-contain rounded-sm shadow-md' data-aos="fade-up" data-aos-duration="800" style={{ backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/Cuba.png?alt=media&token=106f07bc-c8fc-45d4-86cd-17ca3d70625f")` }} ></div>
                        <div className=' w-[48%] h-[300px] md:h-[430px] bg-center bg-no-repeat bg-contain rounded-sm shadow-md md:mt-8 -ml-5 mt-10' data-aos="fade-down" data-aos-duration="800" style={{ backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/Recurso%202.png?alt=media&token=5586dd63-858e-482f-bc3d-6be7c6f8949e")` }} ></div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Block_7;